export enum PaymentModes {
  CASH = "Cash",

  GOOGLE_PAY = "Google Pay",

  PHONE_PAY = "Phone Pay",

  PAYTM = "Paytm",

  BHIM_UPI = "BHIM UPI",

  AMAZON_PAY = "Amazon Pay"
}
