

import { format, parseISO } from "date-fns";
import { Vue, Component, Watch } from "vue-property-decorator";

import { TaskService } from "@/services/task";
import { EmployeeService } from "@/services/employee";

import {
  EmployeeDTO,
  UpdateEmployeeDTO
} from "@/services/employee/employee.dto";
import {
  TaskPaymentDTO,
  TaskEmployeeStatusDTO,
  CreateTaskPaymentDTO
} from "@/services/task/task.dto";

import { PaymentModes } from "@/services/task/task.enum";

interface TableHeader {
  text: string;
  value: string;
}

@Component
export default class Employee extends Vue {
  loading = false;
  employee!: EmployeeDTO;
  status!: TaskEmployeeStatusDTO;
  tempName = "";
  tempAddress = "";
  tempContactNumber = "";
  showSaveButton = false;
  recordTypes = ["Payment wise records"];
  paymentsMethods = Object.values(PaymentModes);
  tempReadingsPaidFor = 0;
  tempPayPerReading = 0;
  tempBonus = 0;
  tempTotal = 0;
  tempTransactionId = "";
  tempPaymentMode: PaymentModes = PaymentModes.CASH;
  headersForPaymentWiseRecordsTable: TableHeader[] = [
    {
      text: "Paid Readings",
      value: "readingsPaidFor"
    },
    {
      text: "Payment Date",
      value: "date"
    },
    {
      text: "Pay per reading",
      value: "payPerReading"
    },
    {
      text: "Added Bonus",
      value: "bonus"
    },
    {
      text: "Total Payment",
      value: "total"
    },
    {
      text: "Payment way",
      value: "mode"
    }
  ];

  data() {
    return {
      employee: {},
      selectedTab: 0,
      status: {},
      showSaveButton: false,
      selectedRecordType: "Payment wise records",
      tempReadingsPaidFor: 0,
      tempPayPerReading: 0,
      tempBonus: 0,
      tempTotal: 0,
      tempPaymentMode: PaymentModes.CASH,
      tempTransactionId: ""
    };
  }

  created() {
    this.$store.commit("liveData/setSelectedActivity", 2);
    this.$store.commit("liveData/setSelectedActivityName", "Employees");
    this.getEmployee();
  }

  @Watch("selectedTab")
  onSelectedTabChanged(newSelectedTabValue: number) {
    if (newSelectedTabValue === 1) {
      this.tempName = this.employee.name;
      this.tempAddress = this.employee.address;
      this.tempContactNumber = this.employee.contactNumber;
    }
  }

  @Watch("tempName")
  onTempNameChanged(newTempName: string, oldTempName: string) {
    console.log(newTempName, oldTempName);
    this.haveDetailsBeenChanged();
  }

  @Watch("tempAddress")
  onTempAddressChanged() {
    this.haveDetailsBeenChanged();
  }

  @Watch("tempReadingsPaidFor")
  onTempReadingsPaidForChanged(newTempReadingsPaidFor: number) {
    if (
      newTempReadingsPaidFor >= 0 &&
      newTempReadingsPaidFor <= this.status.unpaid
    ) {
      this.calculateTotal();
    } else {
      this.tempTotal = 0;
    }
  }

  @Watch("tempPayPerReading")
  onTempPayPerReadingChanged(newTempPayPerReading: number) {
    if (newTempPayPerReading >= 0) {
      this.calculateTotal();
    } else {
      this.tempTotal = 0;
    }
  }

  @Watch("tempBonus")
  onTempBonusChanged(newTempBonus: number) {
    if (newTempBonus >= 0) {
      this.calculateTotal();
    } else {
      this.tempTotal = 0;
    }
  }

  haveDetailsBeenChanged() {
    this.showSaveButton =
      this.employee.name !== this.tempName ||
      this.employee.address !== this.tempAddress ||
      this.employee.contactNumber !== this.tempContactNumber;
  }

  validateReadingsPaidForRule() {
    if (
      this.tempReadingsPaidFor <= this.status.unpaid &&
      this.tempReadingsPaidFor > 0
    )
      return true;
    else return "Invalid number";
  }

  validatePayPerReadingRule() {
    if (this.tempPayPerReading > 0) return true;
    else return "Invalid number";
  }

  validateBonusRule() {
    if (this.tempBonus >= 0) return true;
    else return "Invalid number";
  }

  calculateTotal() {
    this.tempTotal =
      this.tempReadingsPaidFor * this.tempPayPerReading + this.tempBonus;
  }

  async getEmployee() {
    this.loading = true;
    try {
      this.employee = await EmployeeService.getEmployeeById(
        this.$route.params.id
      );
    } catch (err) {
      console.log(err.message);
    }
    this.loading = false;
    await this.getStatus();
  }

  async getStatus() {
    this.loading = true;
    try {
      this.status = await TaskService.getEmployeeStatus(this.$route.params.id);
    } catch (err) {
      console.log(err);
    }
    this.loading = false;
  }

  async createPayment() {
    try {
      const paymentToCreate: CreateTaskPaymentDTO = {
        employeeId: this.$route.params.id,
        total: this.tempTotal,
        payPerReading: this.tempPayPerReading,
        mode: this.tempPaymentMode,
        bonus: this.tempBonus !== 0 ? this.tempBonus : undefined,
        readingsPaidFor: this.tempReadingsPaidFor
      };
      // console.log(paymentToCreate);
      // if (this.tempPaymentMode !== PaymentModes.CASH) {
      //   paymentToCreate.transactionId = this.tempTransactionId;
      // }
      const paymentCreated: TaskPaymentDTO = await TaskService.makePaymentForTasks(
        paymentToCreate
      );
      this.status.payments.push(paymentCreated);
    } catch (err) {
      console.log(err);
    }
  }

  async updateDetailsOfEmployee() {
    const updateDetails: UpdateEmployeeDTO = {
      name: this.tempName,
      address: this.tempAddress
    };

    try {
      this.employee = await EmployeeService.updateEmployee(
        this.$route.params.id,
        updateDetails
      );
    } catch (err) {
      console.log(err.message);
    }
  }

  async deleteEmployee() {
    try {
      await EmployeeService.deleteEmployee(this.$route.params.id);
      this.goBack();
    } catch (err) {
      console.log(err.message);
    }
  }

  goBack() {
    this.$router.back();
  }

  formatDate(date: string) {
    return format(parseISO(date), "dd-MM-yyyy HH:mm");
  }
}
